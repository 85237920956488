<template>
  <section class="cadastro">
    <v-container fluid>
      <div class="acao">
        <template v-for="(val, k) in itemMenu[0]">
          <div class="acao-boxs" :key="k" v-if="val.path !== ''">
            <div class="acao-content">
              <div class="acao-item">
                <v-hover>
                  <template v-slot="{ hover }">
                    <v-card
                      @click="$router.push({name: val.path})"
                      :class="`elevation-${hover ? 6 : 3}`">
                      <v-card-actions class="acao-title">{{ val.realName }}</v-card-actions>
                    </v-card>
                  </template>
                </v-hover>
              </div>
            </div>
          </div>
        </template>
      </div>
    </v-container>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import { filter } from 'lodash'

export default {
  name: 'Produtos',
  computed: {
    ...mapGetters('users', ['listaItens', 'listaMenuTratado']),
    ...mapGetters('roles', ['permiteAcao', 'moduloName']),
    itemMenu () {
      const menuFiltrado = filter(this.$store.getters['users/listaMenuTratado'], (a, k) => {
        return k === 'gestao'
      })

      return filter(menuFiltrado[0], (b, y) => {
        return y === 'Produtos'
      })
    }
  }
}
</script>
<style lang="scss" scoped src="@/assets/styles/view/boxs-menu.scss"></style>